import React from "react";
import "./styles.css";

import wpIcon from "../../assets/social/wp-icon.png";
import igIcon from "../../assets/social/ig-icon.png";
import fbIcon from "../../assets/social/fb-icon.png";
import linkedinIcon from "../../assets/social/linkedin-icon.png";

import heroBg from "../../assets/hero-bg.jpeg";

import logoPv from "../../assets/pv-logo.png";

const Hero = () => {
  return (
    <div
      className="hero flex flex-col max-h-min items-center bg-cover"
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className="flex flex-col items-center ml-72 mobile:ml-0">
        <div className="flex flex-col text-center items-center py-24 mobile:pb-4">
          <img
            src={logoPv}
            className="w-1/3 mobile:w-3/4"
            alt="Logo Programa Valentina"
          />
          <p className="w-3/5	text-5xl mobile:text-2xl mobile:mt-4 font-bold text-white desktop:mt-12">
            NUESTRA VISIÓN ES TRANSFORMAR LA INDUSTRIA DE RRHH
          </p>
        </div>
        <div className="flex flex-row">
          <div class="bg-dark-blue rounded-t-full mx-2 mobile:mx-4">
            <a href="https://api.whatsapp.com/send?phone=50235679557&text=¡Hola!%20👋%20Bienvenido%2Fa%20a%20Programa%20Valentina.%20Agradecemos%20tu%20interés.%20¿En%20qué%20podemos%20apoyarte%20hoy%3F%20😀🚀">
              <img
                className="w-24 h-24 mobile:w-16 mobile:h-16"
                src={wpIcon}
                alt="Whatsapp"
              />
            </a>
          </div>
          <div class="bg-dark-blue rounded-t-full mx-2 mobile:mx-4">
            <a href="https://www.instagram.com/programavalentina/">
              <img
                className="w-24 h-24 mobile:w-16 mobile:h-16"
                src={igIcon}
                alt="Instagram"
              />
            </a>
          </div>
          <div class="bg-dark-blue rounded-t-full mx-2 mobile:mx-4">
            <a href="https://www.facebook.com/ProgramaValentina">
              <img
                className="w-24 h-24 mobile:w-16 mobile:h-16"
                src={fbIcon}
                alt="Facebook"
              />
            </a>
          </div>
          <div class="bg-dark-blue rounded-t-full mx-2 mobile:mx-4">
            <a href="https://www.linkedin.com/company/programa-valentina/">
              <img
                className="w-24 h-24 mobile:w-16 mobile:h-16"
                src={linkedinIcon}
                alt="Linkedin"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
