import React, { useState } from "react";
import "./styles.css";

import ytIcon from "../../assets/yt-icon.png";
import equipo from "../../assets/equipo.png";

import Modal from "../Modal";

const QuienesSomos = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <section className="flex flex-col p-14 mobile:p-4 items-center">
        <h3 className="text-5xl mobile:text-xl text-white text-center font-bold w-3/4">
          Ofrecemos un servicio integral, desde el reclutamiento y selección,
          hasta el desarrollo del talento humano, con procesos objetivos,
          basados 100% en data, enfocados en resultados e impacto del recurso
          humano.
        </h3>
      </section>
      <hr className="divider" />
      <section className="flex flex-row mobile:flex-col text-center p-2 mx-2 ">
        <div className="flex flex-col mobile:flex-col w-2/5 mobile:w-full align-center items-center mobile:mb-8">
          <a
            href="https://www.youtube.com/watch?v=W2S0ESLYgMQ"
            className="w-3/4"
          >
            <img className="scale-75" src={ytIcon} alt="Instagram" />
          </a>
          <p className="text-7xl mobile:text-5xl font-bold text-sky-blue w-1/2 mobile:w-full">
            ¿Quiénes Somos?
          </p>
          <div
            className="bg-white w-2/6 text-black text-3xl mobile:text-xl font-semibold text-center p-4 mt-12 rounded-2xl cursor-pointer"
            onClick={openModal}
          >
            + INFO
          </div>
        </div>
        <div className="flex flex-col mobile:flex-row">
          <img className="desktop:scale-75" src={equipo} alt="Instagram" />
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h1> ¿Quiénes somos?</h1>
          <p>
            Somos una plataforma de soluciones de recursos humanos para nuestros
            aliados; por un lado, candidatos en busca de mejores y más estables
            oportunidades laborales y, por el otro, empresas que desean
            soluciones sostenibles a sus necesidades de recurso humano desde
            talento para plazas de primer ingreso hasta capacitaciones de
            desarrollo profesional.
          </p>
        </Modal>
      </section>
      <hr className="divider" />
    </div>
  );
};

export default QuienesSomos;
