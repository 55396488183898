import React from "react";
import "./styles.css";

import empleo from "../../assets/empleo.png";
import valentinos from "../../assets/valentinos.png";
import contact from "../../assets/contact.png";

const SeccionTalento = () => {
  return (
    <div>
      <section className="flex flex-col items-center text-center">
        <h2 className="text-white mobile:text-5xl">Soy Talento</h2>
        <img className="mobile:w-5/6 my-12" src={valentinos} alt="Valentinos" />
        <img
          className="max-w-60 mobile:max-w-40"
          src={empleo}
          alt="Busco Empleo"
        />
        <div
          className="flex bg-sky-blue text-white w-1/4 mobile:w-2/4 min-h-24 mobile:min-h-12 rounded-full items-center justify-center my-12 mobile:my-6 cursor-pointer"
          onClick={() =>
            window.open(
              "https://airtable.com/appGDoiqHqoyanQGK/shr1hZB4M4KpkGaIL"
            )
          }
        >
          <h3 className="text-3xl mobile:text-xl font-bold">Busco Empleo</h3>
        </div>
        <hr className="divider" />
      </section>
      <section className="flex flex-col items-center text-center pb-12">
        <img
          className="max-w-60 mobile:max-w-40"
          src={contact}
          alt="Contacto"
        />
        <div className="flex flex-col w-full font-extrabold text-6xl mobile:text-xl">
          <span className="text-white">
            Para más información puedes escribirnos a:
          </span>
          <span className="text-sky-blue">
            informacion@programavalentina.com
          </span>
        </div>
      </section>
    </div>
  );
};

export default SeccionTalento;
