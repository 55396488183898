import React from "react";
import Hero from "./components/Hero";
import SeccionServicios from "./components/SeccionServicios";
import SeccionTalento from "./components/SeccionTalento";

import "./App.css";
import QuienesSomos from "./components/QuienesSomos";

const App = () => {
  return (
    <div>
      <Hero />
      <div className="content-background">
        <QuienesSomos />
        <SeccionServicios />
        <SeccionTalento />
      </div>
    </div>
  );
};

export default App;
