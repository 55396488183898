import React, { useState } from "react";
import "./styles.css";

import service1 from "../../assets/service1.png";
import service2 from "../../assets/service2.png";
import service3 from "../../assets/service3.png";
import contratarIcon from "../../assets/contratar.png";

import Modal from "../Modal";

const TarjetaServicio = ({ icono, titulo, onClick }) => {
  return (
    <div className="flex flex-col text-center justify-center items-center min-w-1/3">
      <img className="mobile:w-1/2 w-1/2" src={icono} alt={titulo} />
      <div
        className="flex bg-sky-blue text-white w-3/4 mobile:w-2/4 min-h-24 mobile:min-h-12 rounded-full items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <h3 className="text-3xl mobile:text-xl font-bold">{titulo}</h3>
      </div>
    </div>
  );
};

const ContentReclutamiento = () => (
  <div>
    <h1>Reclutamiento y selección</h1>
    <p>
      Nuestro modelo de reclutamiento y selección de personal se enfoca en
      encontrar al mejor candidato para una vacante utilizando la data y
      apostando por el potencial para lograr el mejor match al mismo tiempo que
      impulsamos los procesos inclusivos. Tenemos la mejor solución para la
      dotación de personal dependiendo de las necesidades de tu empresa.
    </p>
    <div className="row">
      <div className="col">
        <h1 className="sky-text">Reclutamiento individual</h1>
        <p>
          ¡El candidato ideal para tu vacante está aquí! Sin importar si es una
          vacante de primer ingreso o una gerencia estratégica, nuestro equipo
          de reclutadoras se encargará de asesorarte con la información actual
          del mercado y te presentará candidatos con las habilidades necesarias
          para la posición solicitada.
        </p>
      </div>
      <div className="col">
        <h1 className="sky-text">Reclutamiento masivo</h1>
        <p>
          Si necesitas calidad y cantidad esta es tu mejor opción. Nuestro
          servicio de reclutamiento masivo aplica para +8 vacantes de una misma
          posición, con la opción de eficiencias en precio según el número de
          vacantes contratadas.
        </p>
      </div>
    </div>
  </div>
);

const ContentCapacitaciones = () => (
  <div>
    <h1>Capacitaciones a colaboradores</h1>
    <p>
      Descubre nuestro servicio de capacitación diseñado para potenciar las
      habilidades clave de tus colaboradores. Ofrecemos formación en habilidades
      blandas, como trabajo en equipo, comunicación efectiva, liderazgo
      consciente, así como habilidades técnicas, como servicio al cliente y
      ventas de impacto. Nuestro enfoque personalizado asegura un desarrollo
      profesional efectivo para destacar en el competitivo entorno empresarial
      actual. ¡Eleva el rendimiento de tu equipo con nuestras capacitaciones
      especializadas!
    </p>
    <div className="row">
      <div className="col">
        <h1 className="sky-text">Habilidades blandas:</h1>
        <ul>
          <li>Accountability</li>
          <li>Comunicación Efectiva</li>
          <li>Trabajo en Equipo</li>
          <li>Liderazgo Consciente</li>
          <li>Liderazgo Situacional</li>
          <li>Inteligencia Emocional</li>
          <li>Productividad: Gestión del Tiempo</li>
          <li>Pensamiento Creativo</li>
          <li>Y más</li>
        </ul>
      </div>
      <div className="col">
        <h1 className="sky-text"> Habilidades técnicas:</h1>
        <ul>
          <li>Servicio al cliente</li>
          <li>Ventas de Impacto</li>
          <li>Y más</li>
        </ul>
      </div>
    </div>
  </div>
);

const ContentOtros = () => (
  <div>
    <h1>Otros</h1>
    <p>
      Encuentra las mejores soluciones de Recursos Humanos en un solo lugar. En
      estos servicios puedes encontrar excelentes herramientas para incrementar
      la productividad de tu equipo, retención de personal y motivación.
      ¡Demuestra que el activo más importante de tu empresa es el Talento!
    </p>
    <ul>
      <li>Pruebas psicométricas</li>
      <li>Perfilamiento</li>
      <li>Descriptores del puesto</li>
      <li>Evaluaciones de desempeño</li>
      <li>Evaluaciones de clima</li>
      <li>Planes de carrera</li>
      <li>Outplacement</li>
    </ul>
  </div>
);

const SeccionServicios = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  return (
    <section className="servicios">
      <h2 className="mobile:text-5xl font-bold text-center text-white">
        Servicios a Empresas
      </h2>
      <div className="flex flex-row mobile:flex-col desktop:w-2/3 justify-center mobile:space-y-8">
        <TarjetaServicio
          icono={service1}
          titulo="Reclutamiento y selección"
          onClick={() => handleOpenModal(<ContentReclutamiento />)}
        />
        <TarjetaServicio
          icono={service2}
          titulo="Capacitaciones a colaboradores"
          onClick={() => handleOpenModal(<ContentCapacitaciones />)}
        />
        <TarjetaServicio
          icono={service3}
          titulo="Otros"
          onClick={() => handleOpenModal(<ContentOtros />)}
        />
      </div>
      <div
        className="flex flex-row items-center justify-center cursor-pointer bg-white w-1/3 mobile:w-4/5 rounded-full my-12"
        onClick={() =>
          window.open(
            "https://airtable.com/appGDoiqHqoyanQGK/shrjfVWwFYJNTeKsD"
          )
        }
      >
        <h3 className="text-3xl mobile:text-xl">Quiero contratar servicio</h3>
        <img className="h-16" src={contratarIcon} alt="Contratar Servicio" />
      </div>
      <hr className="divider" />
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        {modalContent}
      </Modal>
    </section>
  );
};

export default SeccionServicios;
